@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@font-face {
  font-family: "AeonikMono";
  src: local("AeonikMono"),
    url(./assets/fonts/AeonikMono.otf) format("opentype");
}

body {
  margin: 0;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Prevent the page from scrolling */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

div {
  font-family: AeonikMono, sans-serif;
}

body :not(input):not(textarea):not([contenteditable="true"]) {
  user-select: auto !important;
  -webkit-user-select: auto !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a:link {
  text-decoration: none;
}

/* For Firefox */
* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* For Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  display: none;
}
